import { featureEnabled } from "feature-toggles!sofe";

/* Use this function to check that the hash begins with a certain prefix
 */
export function hashPrefix(location, ...prefixes) {
  return prefixes.some((prefix) => location.hash.indexOf(`#/${prefix}`) === 0);
}

/* Use this function to check that the hash matches a string exactly, excluding the query string.
 */
export function hashEquals(location, string) {
  const hashWithoutQuery =
    location.hash.indexOf("?") >= 0
      ? location.hash.slice(0, location.hash.indexOf("?"))
      : location.hash;
  return hashWithoutQuery === string;
}

/* Use this function to check route-like strings against the URL. The allowed
 * syntax is to use :id inside of your string to indicate that you want to allow
 * for an numeric blob in the url. If we need alphanumeric stuff, let's add an
 * option for that later on
 */
export function containsRoutes(location, ...routes) {
  return routes.some((route) => {
    const parts = route.split(":id");
    const regex = new RegExp(
      parts.reduce((result, part, index) => {
        if (index < parts.length - 1) {
          part = part + `[0-9]+`;
        }

        return result + part;
      }, "")
    );

    return regex.test(location.hash);
  });
}

/* App-specific implementations that determine if they are active or not.
 */

export function primaryNavbar(location) {
  return (
    !mainLoginPages(location) &&
    !supplementalLoginPages(location) &&
    !hashEquals(location, "#/clients/import") && // TODO: ft_crm: remove after hierarchy
    !fourHundredPages(location) &&
    !crmMigrationInProgress(location) &&
    !webviewUi(location) &&
    !location?.pathname?.startsWith("/m/")
  );
}

export function endUserFormsUI(location) {
  return hashPrefix(location, "forms") && !pageNotFound();
}

export function clientMenu(location) {
  const inNewSurvey = containsRoutes(location, `/resolution-cases/:id/survey/`);
  const taskWorkspace = hashPrefix(location, "task/");

  return (
    onlyLettersByClient(location) &&
    !isEditingNote(location) &&
    !inNewSurvey &&
    containsRoutes(
      location,
      "/taxes/client/:id",
      "/clients/:id",
      "/client/:id"
    ) &&
    !location.hash.includes("#/clients/") && // Prevent client-menu showing on client list
    !hashPrefix(location, "files") &&
    !taskWorkspace &&
    !pageNotFound()
  );
}

export function resolutionCasesMenu(location) {
  const inOldSurvey = /mastersurveys/.test(location.hash);
  const inNewSurvey = containsRoutes(location, `/resolution-cases/:id/survey`);
  const inSurvey = inOldSurvey || inNewSurvey;
  return (
    onlyLettersByClient(location) &&
    !inSurvey &&
    containsRoutes(
      location,
      `client/:id/resolution-cases/:id`,
      `clients/:id/resolution-cases/:id`
    ) &&
    !pageNotFound()
  );
}

function onlyLettersByClient(location) {
  return !lettersUI(location) || lettersByClient(location);
}

function lettersByClient(location) {
  return containsRoutes(location, "letters/list/client/:id/");
}

export function lettersUI(location) {
  return hashPrefix(location, "letters") && !pageNotFound();
}

export function docsUI(location) {
  return (
    (hashPrefix(location, "docs") || hashPrefix(location, "files")) &&
    !pageNotFound()
  );
}

export function notificationsUI(location) {
  return hashPrefix(location, "notifications") && !pageNotFound();
}

export function pageNotFound() {
  //This try catch is to help us with some undiagnosable IE/Edge issue.
  //Some how this code was causing an "Unspecified error"
  //So we're going to make the assumption that we should return false if it were to die here.
  try {
    return (
      window.history &&
      window.history.state &&
      window.history.state.pageNotFound
    );
  } catch (e) {
    return false;
  }
}

export function communicationsUI(location) {
  const isActive =
    hashPrefix(location, "communications") || hashPrefix(location, "inbox");
  return isActive && !pageNotFound();
}

export function clientsUI(location) {
  const isClientList =
    hashEquals(location, "#/clients") || location.hash.includes("#/clients/");
  const isClientCreatePage = containsRoutes(
    location,
    "#/new-individual",
    "#/new-business"
  );
  const isClientPage =
    isClientCreatePage ||
    isClientList ||
    containsRoutes(
      location,
      "#/client/:id",
      "#/client-groups",
      "#/communications/clients/:id",
      "#/docs/clients/:id",
      "#/tasks/clients/:id",
      "#/calendar/clients/:id",
      "#/notices/clients/:id",
      "#/tax-prep-tasks/clients/:id"
    );
  const isExcludedPage = containsRoutes(
    location,
    "client/:id/participants/create",
    "client/:id/billing",
    "client/:id/transcripts",
    "client/:id/time"
  );
  return (
    newClientsUI(location) ||
    (isClientPage &&
      !isExcludedPage &&
      !isEditingNote(location) &&
      !pageNotFound())
  );
}

function newClientsUI(location) {
  return (
    location.hash.includes("#/contacts") ||
    hashPrefix(location, "portals") ||
    hashPrefix(location, "global-settings/custom-fields") ||
    hashPrefix(location, "crm/import") ||
    hashPrefix(location, "clients/import") || // TODO: ft_crm: remove after hierarchy
    hashPrefix(location, "templates/client-records") ||
    hashPrefix(location, "crm-migration-preview")
  );
}

export function engagementsUI(location) {
  const engagements = hashPrefix(location, "engagements");
  const engagementItemTemplates = hashPrefix(
    location,
    "templates/engagement-items"
  );
  return (engagements || engagementItemTemplates) && !pageNotFound();
}

export function resolutionCasesUI(location) {
  const resolutionCasesRoute = hashPrefix(location, "resolution-cases");
  return (
    (containsRoutes(location, "admin/global-settings/service-templates") ||
      resolutionCasesRoute ||
      containsRoutes(location, `client/:id/resolution-cases`)) &&
    !pageNotFound()
  );
}

export function tasksUI(location) {
  // The endpoint: /global-setting/tax-return should route to TasksUI
  const mainTasks = hashPrefix(location, "tasks");
  const taskWorkspace = hashPrefix(location, "task");
  const taxReturnTemplate = hashPrefix(location, "global-settings/tax-return");
  const clientRequestTemplates = hashPrefix(
    location,
    "templates/client-requests"
  );
  const taskTemplates = hashPrefix(location, "templates/tasks");
  // don't load tasks-ui for this page
  const tasksDashboardReports = taskDashboard(location);
  return (
    (mainTasks ||
      taxReturnTemplate ||
      taskWorkspace ||
      clientRequestTemplates ||
      taskTemplates) &&
    !tasksDashboardReports &&
    !pageNotFound()
  );
}

export function transcriptsUI(location) {
  return (
    (hashPrefix(location, "transcripts") ||
      containsRoutes(location, "#/client/:id/transcripts")) &&
    !pageNotFound()
  );
}

export function clientTranscripts(location) {
  return (
    containsRoutes(location, "#/client/:id/transcripts") && !pageNotFound()
  );
}

export function globalSettings(location) {
  return (
    location.hash.indexOf("global-settings") > -1 &&
    !mainLoginPages(location) &&
    !supplementalLoginPages(location)
  );
}

export function loginUI(location) {
  return onLoginPage(location);
}

export function onLoginPage(location) {
  // Login-ui handles the settings page for white label because the settings page
  // renders previews of the login page.
  const isWhiteLabelRoute = hashPrefix(location, "white-label");

  return (
    mainLoginPages(location) ||
    isWhiteLabelRoute ||
    supplementalLoginPages(location)
  );
}

export function mainLoginPages(location) {
  return (
    hashPrefix(location, "login") ||
    onLogoutPage(location) ||
    hashPrefix(location, "unsupported-browser")
  );
}

function fourHundredPages(location) {
  return hashPrefix(location, "40");
}

export function crmMigrationInProgress(location) {
  return hashPrefix(location, "crm-migration-in-progress");
}

export function supplementalLoginPages(location) {
  return hashPrefix(location, "oauth") || hashPrefix(location, "select-user");
}

export function onLogoutPage(location) {
  return hashPrefix(location, "logout");
}

export function calendarUI(location) {
  return hashPrefix(location, "calendar") && !pageNotFound();
}

export function noticesUI(location) {
  return hashPrefix(location, "notices") && !pageNotFound();
}

export function taxPrepUI(location) {
  return hashPrefix(location, "tax-prep-tasks") && !pageNotFound();
}

export function billingUI(location) {
  const maybeBillingDashboard = featureEnabled("toggle_billing_reports_v2")
    ? billingDashboard(location)
    : false;

  return (
    (hashPrefix(location, "billing") ||
      containsRoutes(location, "#/client/:id/billing") ||
      hashPrefix(location, "time") ||
      containsRoutes(location, "#/client/:id/time")) &&
    // billing and time dashboards lives in "reporting-ui"
    !maybeBillingDashboard &&
    !hashPrefix(location, "time/dashboard")
  );
}

export function appDashboardUI(location) {
  return hashPrefix(location, "home");
}

export function notesUI(location) {
  return containsRoutes(
    location,
    "#/client/:id/notes",
    "#/client/:id/archived-notes"
  );
}

export function prototypesUI() {
  return hashPrefix(location, "prototypes");
}

export function canopyAdminUI(location) {
  return hashPrefix(location, "canopy-admin");
}

export function reportingUI(location) {
  const maybeBillingDashboard = featureEnabled("toggle_billing_reports_v2")
    ? billingDashboard(location)
    : false;

  return (
    (activityLogReportingUI(location) ||
      insightsReportingUI(location) ||
      clientDashboard(location) ||
      taskDashboard(location) ||
      timeDashboard(location) ||
      maybeBillingDashboard) &&
    !pageNotFound()
  );
}

export function activityLogReportingUI(location) {
  return hashPrefix(location, "activity-log");
}

export function insightsReportingUI(location) {
  return hashPrefix(location, "insights");
}

export const clientDashboard = (location) =>
  hashPrefix(location, "clients/dashboard");
export const taskDashboard = (location) =>
  hashPrefix(location, "tasks/dashboard-reports");
export const timeDashboard = (location) =>
  hashPrefix(location, "time/dashboard");
export const billingDashboard = (location) =>
  hashPrefix(location, "billing/dashboard");

export function webviewUi(location) {
  return hashPrefix(location, "webview");
}

export function fourHundred(location) {
  return /#\/4\d\d/.test(location.hash);
}

function isEditingNote(location) {
  return containsRoutes(location, "/client/:id/notes/:id");
}
